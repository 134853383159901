import axios from 'axios';
import { CommunitiesPayload, CommunitiesResponse } from 'types/communities';
import { CommunitiesMetadataResponse } from 'types/communitiesMetadata';
import { WhiteLabelPayload, WhiteLabelResponse } from 'types/whiteLabel';
import { CommunitiesRatesheetResponse } from 'types/communitiesRatesheet';
import { CarriersResponse } from '../types/Carriers';
import { Guuid } from '../types/common';
import { PostConsentPayload } from '../types/postConsent';
import { FlowType, GetConsentResponse } from '../types/getConsent';
import { GetModelResponse } from '../types/getModal';
import { GetAssignedQuoteResponse } from '../types/getAssignedQuote';
import { CreateLeadRequest } from '../types/api/createLead';
import { GetConsentOptionsResponse } from '../types/getConsentOptions';
import { GetPremiumRangeResponse } from '../types/getPremiumRange';
import { GetPremiumRangesForCommunityResponse } from '../types/getPremiumRangesForCommunity';

export interface CommunitySummary {
  code: string;
  community_name: string;
  external_id: string;
  id: string;
  phase: string;
}

export interface ModelSummary {
  elevation: string;
  external_id: string;
  id: string;
  model_name: string;
  plan_code: string;
  sqft?: string;
  bedroom?: string;
  baths?: string;
}

interface Lead {
  consentUrl?: string;
  leadId: string;
}

export interface BindFlowResponse {
  bind_flow_url: string | null;
}

export const baseURL = window?.appConfig?.API_HOST || process.env.REACT_APP_API_HOST || 'http://localhost:3010';

const client = axios.create({
  baseURL,
});

export function getStateCommunities(state: string) {
  return client.get<Array<CommunitySummary>>(`/v1/public/communities/summary/all?state=${state}`);
}

export function getModelsForCommunity(communityId: string) {
  return client.get<Array<ModelSummary>>(`/v1/public/models/summary/all?communityId=${communityId}`);
}

export function createLead(params: CreateLeadRequest) {
  return client.post<Lead>(`/v1/public/lead`, params);
}

export const getWhiteLabel = (params: WhiteLabelPayload) =>
  client.get<WhiteLabelResponse>(`/v1/white-label`, { params });

export const getCommunitiesMetadata = () => client.get<CommunitiesMetadataResponse>('/v1/public/communities/metadata');

export const getCommunities = (params: CommunitiesPayload) =>
  client.get<CommunitiesResponse>('/v1/public/communities', { params });

export const getCommunitiesRatesheet = (communityId: string) =>
  client.get<CommunitiesRatesheetResponse>(`/v1/public/communities/${communityId}/ratesheet`);

export const getCarriers = () => client.get<CarriersResponse>('/v1/public/carriers');

export const getConsent = (quoteId: Guuid, flowType: FlowType, hmac: string) =>
  client.get<GetConsentResponse>(`/v1/consent/${quoteId}/data?type=${flowType}&hmac=${hmac}`);

export const postConsent = (quoteId: Guuid, search: string, orgName: string, payload: PostConsentPayload) =>
  client.post(`/v1/consent/${quoteId}/application${search}&org=${orgName}`, payload);

export const getModel = (modelId: Guuid) => client.get<GetModelResponse>(`/v1/public/models/${modelId}`);

export const getAssignedQuote = (modelId: Guuid) =>
  client.get<GetAssignedQuoteResponse>(`/v1/public/models/${modelId}/assigned-quote`);

export const getConsentOptions = () => client.get<GetConsentOptionsResponse>('/v1/consent/options');

export const getPremiumRange = (modelId: Guuid) =>
  client.get<GetPremiumRangeResponse>(`/v1/public/models/${modelId}/premium-range`);

export const getPremiumRangesForCommunity = (communityId: string) =>
  client.get<GetPremiumRangesForCommunityResponse>(`/v1/public/communities/${communityId}/premium-ranges`);

export default client;
