import { Col, Row, Typography } from '@hippo/components';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { event } from 'react-fullstory';
import { Redirect, useLocation } from 'react-router';
import { useModelId } from 'helpers/use-route-query';

import { ThemeProviderOverride } from '../../components/ThemeProviderOverride';
import PageContainer from '../../components/PageContainer/PageContainer';
import Box from '../../components/box/Box';
import useWhiteLabel from '../../helpers/useWhiteLabel';
import { PageType } from '../../helpers/enums/pageType';
import { saveSmartHomeKit } from '../../actions/consent';
import { PAGES } from '../../globals/routes';
import { openModal } from '../../actions/modals';
import useGetConsent from '../../helpers/useGetConsent';
import useQuoteId from '../../helpers/use-quote-id';
import useAssignedQuote from '../../helpers/useAssignedQuote';
import useModel from '../../helpers/useModel';

import { HeroImage, HeroTint, StyledCoverageInformation, StyledGrid } from './style';
import { getPropertyInfoPath } from '../../helpers/paths';
import { consentFlowContent, getLandingContent, referralFlowContent } from './content';
import { ModalNames } from '../../types/modals';
import { consentFlowPanelInfo, coverageLeftInfo, referralFlowPanelInfo, collapsedBarContent } from './collapsedBarData';
import useCarriers from '../../helpers/useCarriers';
import getCoveragesFormattedData, { getRightPremiumPriceData, policyTypes } from '../coverages/coveragesData';
import { BasicCoverageInformationBar } from './BasicCoverageInformationBar';
import { CoverageColLeft } from '../coverages/components/ColLeft';
import { CoverageColRight } from '../coverages/components/ColRight';
import { ConsentData } from '../../reducers/consent/types';
import { QuoteData, ReferralModelResponse } from '../../reducers/referral/types';
import { useResponsive } from '../../helpers/useResponsive';
import { OrgNameMapping } from '../../helpers/enums/organization';
import { isToll } from '../../helpers/organization-mapping';
import { QuoteNotFound } from './QuoteNotFound';
import { Flex } from '../../components/Flex';
import { Spinner } from '../../components/Spinner';
import { organizationImages } from '../../utils/organizationImages';
import { Logo } from '../../components/Logo';
import { AgentInfo } from '../coverages/components/AgentInfo';
import { AgencyInfo } from '../coverages/components/AgencyInfo';
import { FixedCtaButton } from './FixedCtaButton';
import { GetMyPolicyButton } from '../coverages/components/GetMyPolicyButton';
import { useLandingPageCTAText } from '../../experiments/hooks/useLandingPageCTAText';
import usePremiumRange from '../../helpers/usePremiumRange';

const hasModelId = (): boolean => {
  const searchParams = new URLSearchParams(location.search);
  const modelId = searchParams.get('modelId');

  return !!modelId;
};

const useColumnData = ({
  isReferralFlow,
  model,
  quote,
  consent,
}: {
  isReferralFlow: boolean;
  model: ReferralModelResponse | undefined;
  quote: QuoteData | undefined;
  consent: ConsentData | undefined;
}) => {
  const community = model?.community;
  const modelName = model?.model_name;

  if (isReferralFlow) {
    return {
      panelInfo: referralFlowPanelInfo(community, modelName, model?.data, quote),
      carrier: undefined,
      coverageLeftInfo: coverageLeftInfo(model?.data, quote),
    };
  } else if (consent) {
    return {
      panelInfo: consentFlowPanelInfo(consent),
      carrier: undefined,
      coverageLeftInfo: getCoveragesFormattedData(consent).coverageRowsData,
    };
  }

  return collapsedBarContent;
};

// Referral flow(with modelId) and consent flow(with quoteId) share the landing page.
//Use modelId and communities_enabled to identify which content/pageType to use.
export const useReferralFlow = (communitiesEnabled?: boolean, modelId?: string | null) =>
  !!communitiesEnabled && !!modelId;

const Landing: React.FC = () => {
  const [tintTransparent, setTintTransparent] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const { isDesktop } = useResponsive();
  const dispatch = useDispatch();
  const consent = useGetConsent();
  const quoteId = useQuoteId();
  const { search } = useLocation();
  const modelId = useModelId();
  const modelsData = useModel();
  const assignedQuote = useAssignedQuote();
  const premiumRange = usePremiumRange();

  const landingPageCTAText = useLandingPageCTAText(true);

  const community = modelsData.data?.community;
  const carrierName = consent.data?.quote.carrier;
  const checkout = consent.data?.checkout;
  const consentOrgName = consent.data?.organization.name;
  const organizationName = consentOrgName || assignedQuote.data?.organization.name;
  const whiteLabel = useWhiteLabel(
    {
      org: organizationName ?? '',
    },
    {
      enabled: Boolean(organizationName),
    }
  );
  const licensesData = whiteLabel.data?.licenses ?? [];
  const builderData = whiteLabel.data?.builder;
  const pageData = whiteLabel.data?.pages;

  const { banner, header, body, licenseInformation, footer } = getLandingContent(
    consentOrgName || assignedQuote.data?.organization.name
  );
  const isReferralFlow = useReferralFlow(assignedQuote.data?.organization.communities_enabled, modelId);
  const agent = isReferralFlow
    ? {
        name: community?.agent?.name ?? '',
        email: community?.agent?.email ?? '',
        phone: community?.agent?.phone_number ?? '',
      }
    : {
        name: consent.data?.agent.agent_name ?? '',
        email: consent.data?.agent.agent_email ?? '',
        phone: consent.data?.agent.agent_phone ?? '',
      };

  const pageType = isReferralFlow ? PageType.Referral : PageType.Consent;

  const images = organizationImages[consentOrgName ?? OrgNameMapping.Default];

  const handleImageLoad = useCallback(() => {
    !tintTransparent && setTintTransparent(true);
  }, [tintTransparent]);

  const saveSmartHomeKitOption = async (val: boolean) => {
    if (carrierName === 'hippo') {
      if (!quoteId) {
        throw new Error('No quote id provided');
      }
      dispatch(saveSmartHomeKit({ quoteId, smart_home_kit: val }));
    }
  };

  const handlePrimaryModalClick = async () => {
    saveSmartHomeKitOption(true);
    dispatch(push(`/consent/${quoteId}/${PAGES.eligibility}${search}`));
  };

  const handleSecondaryModalClick = async () => {
    saveSmartHomeKitOption(false);
    dispatch(push(`/consent/${quoteId}/${PAGES.eligibility}${search}`));
  };

  const openRedirectModal = async () => {
    event('Get My Policy');
    if (modelId) {
      dispatch(push(getPropertyInfoPath({ communityId: community?.id || '', modelId })));
    } else {
      if (carrierName === 'hippo') {
        if (consent.data?.smart_home_kit) {
          if (!quoteId) {
            throw new Error('No quote id provided');
          }
          dispatch(
            openModal({
              modalName: ModalNames.SmartHomeConsentModal,
              onPrimaryButtonClick: handlePrimaryModalClick,
              onSecondaryButtonClick: handleSecondaryModalClick,
            })
          );
        } else {
          saveSmartHomeKitOption(false);
          dispatch(push(`/consent/${quoteId}/${PAGES.eligibility}${search}`));
        }
      } else {
        dispatch(push(`/consent/${quoteId}/${PAGES.eligibility}${search}`));
      }
    }
  };

  const carriers = useCarriers();
  const carrier = carriers.data?.find(({ name }) => name === consent.data?.quote.carrier);

  const { coverageLeftInfo, panelInfo } = useColumnData({
    isReferralFlow,
    model: modelsData.data,
    quote: assignedQuote.data,
    consent: consent.data,
  });

  if (assignedQuote.isLoading || premiumRange.isLoading) {
    return (
      <Flex flex='1' height='100vh' alignItems='center' justifyContent='center'>
        <Spinner />
      </Flex>
    );
  }

  if (location.pathname === '/' && !hasModelId()) {
    return <Redirect to='/404' />;
  }

  if (assignedQuote.data === undefined && Boolean(modelId)) {
    return <QuoteNotFound tintTransparent={tintTransparent} handleImageLoad={handleImageLoad} />;
  }

  const isCarrierHippo = carrierName === 'hippo';

  const productType = assignedQuote.data?.product_type || consent.data?.quote.product;
  const agencyInfo = (
    <AgencyInfo
      isHippo={isCarrierHippo}
      insurer={carrier?.display_name ?? undefined}
      reference={consent.data?.reference_id ?? undefined}
      policyType={policyTypes[productType ?? '']}
    />
  );

  const agentInfo = agent ? <AgentInfo name={agent.name} email={agent.email} phone={agent.phone} /> : null;

  return (
    <ThemeProviderOverride orgName={consentOrgName}>
      <FixedCtaButton
        buttonRef={buttonRef}
        pageType={pageType}
        openRedirectModal={openRedirectModal}
        premiumRange={premiumRange.data}
        premiumMonthly={panelInfo.premiumMonthly}
        premium={panelInfo.premium}
      />
      <PageContainer backgroundColor={['primary50', 'primary50', 'white']}>
        <Box position='absolute' width='100%' minHeight='700px'>
          <HeroImage
            width='100%'
            height='700px'
            onLoad={handleImageLoad}
            srcSet={pageData && images.hero}
            orgName={organizationName}
          />
          <HeroTint transparent={tintTransparent} />
        </Box>

        <Box display='block' position='relative'>
          <StyledGrid>
            <Row>
              <Col
                size={1}
                alignItems={['center', 'flex-start', 'flex-start']}
                marginBottom='50px'
                marginTop='45px'
                maxHeight='50px'
                maxWidth='100%'
              >
                {organizationName && (
                  <Logo
                    logoUrl={images.logoDefault}
                    organizationName={organizationName}
                    logoDefaultUrl={images.logoWhite}
                    logoMobileUrl={images.logoWhite}
                  />
                )}
              </Col>
            </Row>
            {!isToll(consentOrgName) ? (
              <Row paddingTop={[0, '20px', '20px']}>
                <Col size={[1, 1, 7 / 12]}>
                  <Typography type={['display8', 'display4', 'display4']} color='white'>
                    <div style={{ fontWeight: 200 }}>
                      {isReferralFlow ? referralFlowContent.title : consentFlowContent.title}
                    </div>
                  </Typography>
                </Col>
                {!isReferralFlow ? (
                  <Col size={[1, 1, 1]}>
                    <Typography type={['display8', 'display4', 'display4']} color='white'>
                      <div style={{ fontWeight: 200 }}>
                        {checkout?.personal_information?.first_name} {checkout?.personal_information?.last_name}
                      </div>
                    </Typography>
                  </Col>
                ) : null}
              </Row>
            ) : (
              <Row paddingTop='20px'>
                <Col size={[1, 1, 7 / 12]}>
                  <Typography type={['display8', 'display4', 'display4']} color={['gray900', 'gray900', 'white']}>
                    <div style={{ fontWeight: 200 }}>Congratulations on buying your new Toll-Brothers house</div>
                  </Typography>
                </Col>
              </Row>
            )}
            <StyledCoverageInformation>
              <Col minHeight='200px' paddingLeft='0' paddingRight='0'>
                <BasicCoverageInformationBar
                  panelInfo={panelInfo}
                  carrier={carrier}
                  premiumRange={premiumRange.data}
                  isDesktop={isDesktop}
                  ctaButton={
                    <Box
                      marginBottom={[3, 1, 0]}
                      marginTop={[4, 4, 0]}
                      marginX={2}
                      className='__no_print'
                      textAlign='center'
                      ref={buttonRef}
                    >
                      <GetMyPolicyButton
                        pageType={pageType}
                        buttonClick={isReferralFlow ? openRedirectModal : undefined}
                        smartHomeKit={!isReferralFlow ? consent.data?.smart_home_kit || false : false}
                        isCarrierHippo={isCarrierHippo}
                        quoteId={!isReferralFlow ? quoteId : ''}
                        search={!isReferralFlow ? search : ''}
                        buttonSize='medium'
                      >
                        {landingPageCTAText}
                      </GetMyPolicyButton>
                    </Box>
                  }
                />
                <Row margin={[0, 0, 0]}>
                  <CoverageColLeft
                    coverageRowsData={coverageLeftInfo || []}
                    quoteLink={assignedQuote.data?.quote_doc_link ?? ''}
                  />

                  {isReferralFlow ? (
                    <CoverageColRight
                      pageType={pageType}
                      agencyInfo={agencyInfo}
                      agentInfo={agentInfo}
                      premium={getRightPremiumPriceData(0)}
                      quoteLink={assignedQuote.data?.quote_doc_link ?? ''}
                      ctaButton={
                        <Box marginBottom={2} marginTop={4} className='__no_print' textAlign='center' ref={buttonRef}>
                          <GetMyPolicyButton
                            pageType={pageType}
                            smartHomeKit={false}
                            buttonClick={openRedirectModal}
                            isCarrierHippo={isCarrierHippo}
                            quoteId=''
                            search=''
                            buttonSize='medium'
                          >
                            {landingPageCTAText}
                          </GetMyPolicyButton>
                        </Box>
                      }
                    />
                  ) : (
                    <CoverageColRight
                      pageType={pageType}
                      agencyInfo={agencyInfo}
                      agentInfo={agentInfo}
                      premium={
                        consent.data?.quote.premium
                          ? getRightPremiumPriceData(consent.data?.quote.premium)
                          : {
                              monthly: '',
                              yearly: '',
                            }
                      }
                      quoteLink={consent.data?.coverages.quote_doc_link ?? ''}
                      ctaButton={
                        <Box marginBottom={2} marginTop={4} className='__no_print' textAlign='center' ref={buttonRef}>
                          <GetMyPolicyButton
                            pageType={pageType}
                            smartHomeKit={consent.data?.smart_home_kit || false}
                            isCarrierHippo={isCarrierHippo}
                            quoteId={quoteId}
                            search={search}
                            buttonSize='medium'
                          >
                            {landingPageCTAText}
                          </GetMyPolicyButton>
                        </Box>
                      }
                    />
                  )}
                </Row>
              </Col>
            </StyledCoverageInformation>
            {banner}
            <Row>
              <Col size={[1, 1, 8 / 12]} offset={[0, 0, 2 / 12]}>
                <Typography type='body1' color='primary700' textAlign='center' as='h2' marginTop={header.space}>
                  {header.text}
                </Typography>
              </Col>
            </Row>
          </StyledGrid>
          {pageData && builderData
            ? body({ whiteLabel: { pages: pageData, licenses: licensesData, builder: builderData } })
            : null}

          {licenseInformation(licensesData)}
        </Box>
        {whiteLabel.data &&
          consent.data &&
          footer({
            whiteLabel: whiteLabel.data,
            footerLinks: whiteLabel.data?.pages.footer,
            consentWhiteLabel: consent.data?.white_label,
          })}
      </PageContainer>
    </ThemeProviderOverride>
  );
};

export default Landing;
