import { State } from '../reducers/createRootReducer';
import { ModalNames, ModalProps } from '../types/modals';
import fsa from 'typescript-fsa';
import { Dispatch } from 'redux';

type AnyModalProps = Omit<ModalProps, 'isOpen'> & {
  modalName:
    | ModalNames.SmartHomeConsentModal
    | ModalNames.SecondHomeModal
    | ModalNames.SwimmingPoolModal
    | ModalNames.DogsModal
    | ModalNames.ExoticPetsModal
    | ModalNames.CoverageModal;
  data?: unknown;
};

type ModalOptions = AnyModalProps;

const actionCreator = fsa('MODAL');

export const openModalAction = actionCreator<ModalOptions>('OPEN');
export const queueModalAction = actionCreator<ModalOptions>('QUEUE');
export const closeModalAction = actionCreator<void>('CLOSE');
export const openQueuedModalAction = actionCreator<void>('OPEN_QUEUED');

export type OpenModal = (options: ModalOptions) => (dispatch: Dispatch, getState: () => State) => void;
export const openModal: OpenModal = (options) => (dispatch: any, getState: () => State) => {
  const { modal } = getState();
  if (modal.isOpen) {
    dispatch(queueModalAction(options));
  } else {
    dispatch(openModalAction(options));
  }
};

export const closeModal = () => (dispatch: any, getState: () => State) => {
  const { modal } = getState();
  if (modal.queue.length > 0) {
    dispatch(openQueuedModalAction());
  } else {
    dispatch(closeModalAction());
  }
};
